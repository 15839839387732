import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_39cc8c0a from 'nuxt_plugin_plugin_39cc8c0a' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_wmIcons_9120968a from 'nuxt_plugin_wmIcons_9120968a' // Source: ./wmIcons.js (mode: 'all')
import nuxt_plugin_nuxtplugin5cb5a4e6_1e4dac8c from 'nuxt_plugin_nuxtplugin5cb5a4e6_1e4dac8c' // Source: ./nuxt.plugin.5cb5a4e6.js (mode: 'server')
import nuxt_plugin_image_76e4d038 from 'nuxt_plugin_image_76e4d038' // Source: ./image.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_1ca74891 from 'nuxt_plugin_cookieuniversalnuxt_1ca74891' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_fc643b1c from 'nuxt_plugin_pluginutils_fc643b1c' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginseo_ebb06b74 from 'nuxt_plugin_pluginseo_ebb06b74' // Source: ./nuxt-i18n/plugin.seo.js (mode: 'all')
import nuxt_plugin_pluginrouting_0840c986 from 'nuxt_plugin_pluginrouting_0840c986' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_76606152 from 'nuxt_plugin_pluginmain_76606152' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_runtimeplugin0811e34c_10df806a from 'nuxt_plugin_runtimeplugin0811e34c_10df806a' // Source: ./runtime.plugin.0811e34c.mjs (mode: 'all')
import nuxt_plugin_customerplugin_5259a6fc from 'nuxt_plugin_customerplugin_5259a6fc' // Source: ./customer.plugin.js (mode: 'all')
import nuxt_plugin_runtimeplugin419bafdd_5d19ca90 from 'nuxt_plugin_runtimeplugin419bafdd_5d19ca90' // Source: ./runtime.plugin.419bafdd.mjs (mode: 'all')
import nuxt_plugin_paymentSveaPlugin_701a0727 from 'nuxt_plugin_paymentSveaPlugin_701a0727' // Source: ./paymentSveaPlugin.js (mode: 'all')
import nuxt_plugin_postscribeclient_87419ba0 from 'nuxt_plugin_postscribeclient_87419ba0' // Source: ./postscribe.client.js (mode: 'client')
import nuxt_plugin_runtimeplugin5edf1204_684a9fdc from 'nuxt_plugin_runtimeplugin5edf1204_684a9fdc' // Source: ./runtime.plugin.5edf1204.mjs (mode: 'all')
import nuxt_plugin_runtimeplugin699822f9_1f4e24cc from 'nuxt_plugin_runtimeplugin699822f9_1f4e24cc' // Source: ./runtime.plugin.699822f9.mjs (mode: 'all')
import nuxt_plugin_runtimeplugin1b971aed_5d49b2c3 from 'nuxt_plugin_runtimeplugin1b971aed_5d49b2c3' // Source: ./runtime.plugin.1b971aed.mjs (mode: 'all')
import nuxt_plugin_distplugin6b01401f_366a6581 from 'nuxt_plugin_distplugin6b01401f_366a6581' // Source: ./dist.plugin.6b01401f.mjs (mode: 'all')
import nuxt_plugin_pluginsfc6eeda53a_18c39af3 from 'nuxt_plugin_pluginsfc6eeda53a_18c39af3' // Source: ./plugins.fc.6eeda53a.mjs (mode: 'all')
import nuxt_plugin_coreplugin406f3188_595dc2ef from 'nuxt_plugin_coreplugin406f3188_595dc2ef' // Source: ./core.plugin.406f3188.ts (mode: 'all')
import nuxt_plugin_pluginstokenexpired051d9c69_4f0a3a55 from 'nuxt_plugin_pluginstokenexpired051d9c69_4f0a3a55' // Source: ./plugins.token-expired.051d9c69.ts (mode: 'all')
import nuxt_plugin_pluginsdompurifydf7a14ae_9952ec32 from 'nuxt_plugin_pluginsdompurifydf7a14ae_9952ec32' // Source: ./plugins.dompurify.df7a14ae.ts (mode: 'all')
import nuxt_plugin_pluginsi18n2e3ce382_70fd9af0 from 'nuxt_plugin_pluginsi18n2e3ce382_70fd9af0' // Source: ./plugins.i18n.2e3ce382.ts (mode: 'all')
import nuxt_plugin_pluginsfixLocaleRoutes8c159efe_016b8430 from 'nuxt_plugin_pluginsfixLocaleRoutes8c159efe_016b8430' // Source: ./plugins.fixLocaleRoutes.8c159efe.ts (mode: 'all')
import nuxt_plugin_pluginse2etesting18a759c8_217fe178 from 'nuxt_plugin_pluginse2etesting18a759c8_217fe178' // Source: ./plugins.e2e-testing.18a759c8.js (mode: 'all')
import nuxt_plugin_deviceplugin_252d3974 from 'nuxt_plugin_deviceplugin_252d3974' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_141bf9c5 from 'nuxt_plugin_workbox_141bf9c5' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_3315abd8 from 'nuxt_plugin_metaplugin_3315abd8' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_37bd514c from 'nuxt_plugin_iconplugin_37bd514c' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_storeConfigPlugin_cbd09bc6 from 'nuxt_plugin_storeConfigPlugin_cbd09bc6' // Source: ../node_modules/@wemade-vsf/core/lib/runtime/plugins/storeConfigPlugin.mjs (mode: 'server')
import nuxt_plugin_meta_5a8dd7cd from 'nuxt_plugin_meta_5a8dd7cd' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Det frivillige skyttervesen","titleTemplate":"%s - Det frivillige skyttervesen","meta":[{"charset":"utf8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"hid":"og:site_name","property":"og:site_name","name":"og:site_name","content":"Det frivillige skyttervesen"},{"hid":"og:title","name":"og:title","property":"og:title","content":"","template":(chunk) => chunk ? `${chunk} - Det frivillige skyttervesen` : 'Det frivillige skyttervesen'},{"hid":"og:description","property":"og:description","content":""},{"hid":"apple-mobile-web-app-title","property":"apple-mobile-web-app-title","content":"Det frivillige skyttervesen"},{"name":"og:image","hid":"og:image","property":"og:image","content":"\u002Fogimage.jpg"},{"name":"generator","content":"Vue Storefront 2"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"bodyAttrs":{"id":"html-body"},"style":[],"script":[]},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_39cc8c0a === 'function') {
    await nuxt_plugin_plugin_39cc8c0a(app.context, inject)
  }

  if (typeof nuxt_plugin_wmIcons_9120968a === 'function') {
    await nuxt_plugin_wmIcons_9120968a(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nuxtplugin5cb5a4e6_1e4dac8c === 'function') {
    await nuxt_plugin_nuxtplugin5cb5a4e6_1e4dac8c(app.context, inject)
  }

  if (typeof nuxt_plugin_image_76e4d038 === 'function') {
    await nuxt_plugin_image_76e4d038(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_1ca74891 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_1ca74891(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_fc643b1c === 'function') {
    await nuxt_plugin_pluginutils_fc643b1c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseo_ebb06b74 === 'function') {
    await nuxt_plugin_pluginseo_ebb06b74(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_0840c986 === 'function') {
    await nuxt_plugin_pluginrouting_0840c986(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_76606152 === 'function') {
    await nuxt_plugin_pluginmain_76606152(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin0811e34c_10df806a === 'function') {
    await nuxt_plugin_runtimeplugin0811e34c_10df806a(app.context, inject)
  }

  if (typeof nuxt_plugin_customerplugin_5259a6fc === 'function') {
    await nuxt_plugin_customerplugin_5259a6fc(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin419bafdd_5d19ca90 === 'function') {
    await nuxt_plugin_runtimeplugin419bafdd_5d19ca90(app.context, inject)
  }

  if (typeof nuxt_plugin_paymentSveaPlugin_701a0727 === 'function') {
    await nuxt_plugin_paymentSveaPlugin_701a0727(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_postscribeclient_87419ba0 === 'function') {
    await nuxt_plugin_postscribeclient_87419ba0(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin5edf1204_684a9fdc === 'function') {
    await nuxt_plugin_runtimeplugin5edf1204_684a9fdc(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin699822f9_1f4e24cc === 'function') {
    await nuxt_plugin_runtimeplugin699822f9_1f4e24cc(app.context, inject)
  }

  if (typeof nuxt_plugin_runtimeplugin1b971aed_5d49b2c3 === 'function') {
    await nuxt_plugin_runtimeplugin1b971aed_5d49b2c3(app.context, inject)
  }

  if (typeof nuxt_plugin_distplugin6b01401f_366a6581 === 'function') {
    await nuxt_plugin_distplugin6b01401f_366a6581(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfc6eeda53a_18c39af3 === 'function') {
    await nuxt_plugin_pluginsfc6eeda53a_18c39af3(app.context, inject)
  }

  if (typeof nuxt_plugin_coreplugin406f3188_595dc2ef === 'function') {
    await nuxt_plugin_coreplugin406f3188_595dc2ef(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginstokenexpired051d9c69_4f0a3a55 === 'function') {
    await nuxt_plugin_pluginstokenexpired051d9c69_4f0a3a55(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsdompurifydf7a14ae_9952ec32 === 'function') {
    await nuxt_plugin_pluginsdompurifydf7a14ae_9952ec32(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsi18n2e3ce382_70fd9af0 === 'function') {
    await nuxt_plugin_pluginsi18n2e3ce382_70fd9af0(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfixLocaleRoutes8c159efe_016b8430 === 'function') {
    await nuxt_plugin_pluginsfixLocaleRoutes8c159efe_016b8430(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginse2etesting18a759c8_217fe178 === 'function') {
    await nuxt_plugin_pluginse2etesting18a759c8_217fe178(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_252d3974 === 'function') {
    await nuxt_plugin_deviceplugin_252d3974(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_141bf9c5 === 'function') {
    await nuxt_plugin_workbox_141bf9c5(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_3315abd8 === 'function') {
    await nuxt_plugin_metaplugin_3315abd8(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_37bd514c === 'function') {
    await nuxt_plugin_iconplugin_37bd514c(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_storeConfigPlugin_cbd09bc6 === 'function') {
    await nuxt_plugin_storeConfigPlugin_cbd09bc6(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_5a8dd7cd === 'function') {
    await nuxt_plugin_meta_5a8dd7cd(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
